<template>
  <div class="page-wrapper d-flex flex-column justify-content-center m-auto p-5">
    <div class="fs-4 fw-bold mb-5">{{ $t('support.contact_customer_support') }}</div>
    <div class="app-form-control mb-3">
      <div class="label mb-2">{{ $t('support.your_name') }}</div>
      <b-form-input
          class="support-input px-4"
          :class="{error: error.name}"
          v-model="form.name"
          trim
          :placeholder="$tc('support.minimum_length', 2)"
      />
      <span v-if="error.name" class="error-msg">{{ $t('validation.invalid_value') }}</span>
    </div>
    <div class="app-form-control mb-3">
      <div class="label mb-2">{{ $t('support.email_address') }}</div>
      <b-form-input
          class="support-input px-4"
          :class="{error: error.email}"
          v-model="form.email"
          placeholder="email@gmail.com"
      />
      <span v-if="error.email" class="error-msg">{{ $t('validation.invalid_value') }}</span>
    </div>
    <div class="app-form-control mb-3">
      <div class="label mb-2">{{ $t('support.reason_for_inquiry') }}</div>
      <b-form-select
          class="support-input px-4 w-100"
          :class="{error: error.reasonId}"
          v-model="form.reasonId"
          :options="reasons"
      />
      <span v-if="error.reasonId" class="error-msg">{{ $t('validation.invalid_value') }}</span>
    </div>
    <div class="app-form-control mb-3">
      <div class="label mb-2">{{ $t('support.your_message') }}</div>
      <b-form-textarea
          class="support-input px-4 py-2"
          :class="{error: error.message}"
          v-model="form.message"
          :placeholder="$tc('support.minimum_length', 5)"
          rows="4"
      />
      <span v-if="error.message" class="error-msg">{{ $t('validation.invalid_value') }}</span>
    </div>

    <b-button class="support-btn border-0 fw-bolder mt-4" @click="submit">{{ $t('support.send') }}</b-button>
  </div>
</template>

<script>
import config from "@/config";

export default {
  data: () => ({
    form: {
      name: '',
      email: '',
      reasonId: 5,
      message: ''
    },
    error: {
      name: false,
      email: false,
      reasonId: false,
      message: false
    }
  }),
  beforeMount() {
    this.initClarity(config.clarityId.onboardingId)
  },
  computed: {
    validationError() {
      return !Object.values(this.error).some(el => el);
    },
    reasons() {
      return [
        {value: 1, text: this.$t('support_reason.order_not_received')},
        {value: 2, text: this.$t('support_reason.billing_inquiries')},
        {value: 3, text: this.$t('support_reason.subscription_cancellation')},
        {value: 4, text: this.$t('support_reason.technical_difficulties')},
        {value: 5, text: this.$t('support_reason.other_question')}
      ];
    }
  },
  watch: {
    form: {
      handler() {
        this.error.name = false;
        this.error.email = false;
        this.error.reasonId = false;
        this.error.message = false;
      },
      deep: true
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    submit() {
      this.validateForm();
      if (this.validationError) return false;

      console.log('send request');
      console.log(this.form);
      // this.$store.dispatch('appStore/sendSupportMessage', this.form);
    },
    validateForm() {
      const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      this.error.name = this.form.name.length < 2;
      this.error.email = !emailPattern.test(this.form.email);
      this.error.reasonId = !this.form.reasonId;
      this.error.message = this.form.message.length < 5;
    }
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - 50px);
  max-width: 500px;

  .app-form-control {
    position: relative;

    .label {
      text-align: left;
    }

    .support-input {
      min-height: 56px;
      background: #fff;
      border: 2px solid transparent;
      border-radius: 16px;

      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      &.custom-select {
        background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='darkgray' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
      }

      &::placeholder {
        color: darkgrey;
      }

      &:focus-visible {
        outline: none !important;
      }

      &.error {
        //color: #F67272;
        border: 2px solid #F67272;
      }
    }

    .error-msg {
      position: absolute;
      right: 0;
      bottom: -19px;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 12px;
      color: #F67272;
    }
  }

  .support-btn {
    padding: 15px 25px;
    background: #54A96C;
    font-size: 16px;
    border-radius: 30px;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 320px) {
  .page-wrapper::v-deep {
    .app-form-control {
      .label {
        font-size: 14px;
      }

      .support-input {
        padding-right: 15px !important;
        padding-left: 15px !important;
        font-size: 14px;

        &::placeholder {
          font-size: 14px;
        }
      }

      .error-msg {
        position: absolute;
        right: 0;
        bottom: -19px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 12px;
        color: #F67272;
      }
    }

    .support-btn {
      padding: 15px 25px;
      background: #54A96C;
      font-size: 16px;
      border-radius: 30px;
    }
  }
}
</style>